import {
  Alert,
  Box,
  Button,
  Modal,
  Snackbar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";

const InquiryInputModal = styled(Modal)`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ModalContext = styled(Box)`
  border-radius: 10px;
  padding: 25px;
  min-height: 400px;
  min-width: 300px;
  width: 80%;
  background-color: white;
`;

const InquiryWriteArea = styled(TextField)`
  margin-top: 30px;
`;

const SendButtonWrapper = styled(Typography)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

type Props = {
  isOpen: boolean;
  onModalChange: (open: boolean) => void;
};

export const InquiryModal: FC<Props> = ({ isOpen, onModalChange }) => {
  // 送信するメッセージを詰める
  const [message, setMessage] = useState<string>("");
  //0: 未送信 1:送信済
  const [sendStatus, setSendStatus] = useState<number>(0);
  const [IsAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  // 待機用
  const _sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  // 問い合わせ内容の送信
  const sendInquiry = async () => {
    const param = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: message,
      }),
    };
    const path = process.env.REACT_APP_API ?? "";
    const response = await fetch(`${path}inquiry`, param);
    if (!response.ok) {
      setIsAlertOpen(true);
      return;
    }
    // 送信済みボタンにする
    setSendStatus(1);
    // 1秒待つ（待たないと送信済みボタンを見てもらう時間がない）
    await _sleep(1000);
    // メッセージを空にする
    setMessage("");
    // 送信ボタンにする
    setSendStatus(0);
    onModalChange(false);
  };

  return (
    <InquiryInputModal
      open={isOpen}
      onClose={() => {
        onModalChange(false);
      }}
    >
      <ModalContext>
        <Typography>
          <span>
            ・要望、バグ、その他連絡が必要な場合にお使いください。
            <br />
            ・返信が必要な場合はメールアドレスを記載してください。
          </span>
        </Typography>
        <InquiryWriteArea
          label="内容"
          multiline
          fullWidth
          rows={9}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
        />
        <SendButtonWrapper>
          {sendStatus === 0 && (
            <Button
              variant="outlined"
              disabled={message.length === 0}
              onClick={() => {
                sendInquiry();
              }}
            >
              送信
            </Button>
          )}
          {sendStatus === 1 && (
            <Button disabled variant="outlined">
              送信しました
            </Button>
          )}
        </SendButtonWrapper>
        <Snackbar
          open={IsAlertOpen}
          autoHideDuration={4000}
          onClose={() => {
            setIsAlertOpen(false);
          }}
        >
          <Alert
            onClose={() => {
              setIsAlertOpen(false);
            }}
            severity="warning"
          >
            送信に失敗しました。もう一度試してください。
          </Alert>
        </Snackbar>
      </ModalContext>
    </InquiryInputModal>
  );
};
