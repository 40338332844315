import styled from "@emotion/styled";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { History } from "@mui/icons-material";

const Wrapper = styled(Box)`
  width: 100%;
  border-bottom: 2px solid #eee;
`;

type StationSearchType = {
  station: string;
  time: number;
  transfer: number;
};

type Props = {
  newHistory: StationSearchType | null;
  propSelected: (result: StationSearchType) => void;
};

export const SearchHistory: FC<Props> = ({ newHistory, propSelected }) => {
  const [histories, setHistories] = useState<StationSearchType[]>([]);

  useEffect(() => {
    if (newHistory == null) return;
    // historiesに検索履歴を降順で詰め、3個以上になったら古いものを削除
    const tempHistories = [newHistory, ...histories];
    if (tempHistories.length > 3) {
      tempHistories.pop();
    }
    setHistories(tempHistories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHistory]);

  return (
    <Wrapper>
      <List>
        {histories.map((history: StationSearchType, i: number) => (
          <ListItem button key={i} onClick={() => propSelected(history)}>
            <ListItemIcon>
              <History />
            </ListItemIcon>
            <ListItemText>
              {history.station} - {history.time}分
              {history.transfer === -1 ? "" : ` - 乗換${history.transfer}回`}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};
