import React, { useState, FC, useEffect, useCallback } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import { MarkerType } from "../App";
import styled from "@emotion/styled";
import { MapMarker } from "./MapMarker";
import { ClusterMarker } from "./ClusterMarker";
import { Alert, Snackbar } from "@mui/material";

const MapCanvasElement = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type Props = {
  marker: MarkerType[];
};

type latLonType = {
  lat: number;
  lng: number;
};

export const MapCanvas: FC<Props> = ({ marker }) => {
  // routeInfoは路線と駅情報
  const [routeInfo, setRouteInfo] = useState<number[]>([]);
  // onGoogleApiLoadedで出来上がるmapとmapsを保存して路線描画に使う
  const [keepmap, setmap] = useState<any>("");
  const [keepmaps, setmaps] = useState<any>("");
  const [bounds, setBounds] = useState<any | null>(null);
  // 路線描画のオブジェクトを持っておき、新しい検索を行った際に消去する
  const [lineObjects, setLineObjects] = useState<any[]>([]);
  // マップの中心点
  const [center, setCenter] = useState<latLonType>({ lat: 35, lng: 135 });
  // 拡大率
  const [zoom, setZoom] = useState<number>(5);
  // クラスタリング用マーカー情報
  const [points, setPoints] = useState<any>({});
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20 },
  });
  const [IsAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  // 路線に所属する駅一覧を取得し、整形する
  const lineStations = async () => {
    const path = process.env.REACT_APP_API ?? "";
    const response = await fetch(`${path}line_stations`);
    if (!response.ok) {
      setIsAlertOpen(true);
      return;
    }
    const j = await response.json();
    const routeInfoArray: number[] = [];
    // 路線番号は取り除いている。路線単位で駅idを入れた配列を作成
    for (let route in j.data) {
      let strstations = j.data[route];
      if (strstations.length > 0) {
        routeInfoArray.push(strstations.map(Number));
      }
    }
    setRouteInfo(routeInfoArray);
  };

  useEffect(() => {
    lineStations();
  }, []);

  // 路線描画
  const drawPolyline = useCallback(
    (polylineroute: latLonType[]) => {
      const lineObject = new keepmaps.Polyline({
        path: polylineroute,
        strokeColor: "rgba(0, 153, 204, 0.5)",
      });
      lineObject.setMap(keepmap);
      return lineObject;
    },
    [keepmap, keepmaps.Polyline]
  );

  // 描画したい路線（駅）の探索
  const seekRouteMarker = useCallback(
    (pins: MarkerType[], routeinfo: any) => {
      const saveLineObject = [];
      for (let line of routeinfo) {
        let existStations = [];
        for (let station of line) {
          for (let pin of pins) {
            if (pin.stationId === station) {
              existStations.push({
                lat: pin.latitude,
                lng: pin.longitude,
              });
            }
          }
        }
        // length=0はその路線の結果が0件なのでスルー。
        // length=1は始発（終着）駅のみ対象のため、これまた路線の描画はスルー。
        if (existStations.length > 1) {
          const lineObject = drawPolyline(existStations);
          saveLineObject.push(lineObject);
        }
      }
      return saveLineObject;
    },
    [drawPolyline]
  );

  // 拡大率の計算と変更
  const changeZoom = (marker: MarkerType[]) => {
    // サイト開いてすぐの拡大率
    if (marker.length === 0) {
      setZoom(5);
      return;
    }
    // マーカーの緯度、経度それぞれ(Max-Min)を求めて、より大きな数値から拡大率を決定する
    const latitudeList = marker.map((item) => item.latitude);
    const longitudeList = marker.map((item) => item.longitude);
    const latitudeDiff = Math.max(...latitudeList) - Math.min(...latitudeList);
    const longitudeDiff =
      Math.max(...longitudeList) - Math.min(...longitudeList);
    const latLngDiff = Math.max(latitudeDiff, longitudeDiff);
    if (latLngDiff > 5) {
      setZoom(7);
    } else if (latLngDiff > 2.5) {
      setZoom(8);
    } else if (latLngDiff > 1.25) {
      setZoom(9);
    } else if (latLngDiff > 0.63) {
      setZoom(10);
    } else if (latLngDiff > 0.32) {
      setZoom(11);
    } else {
      setZoom(12);
    }
  };

  // 前回検索結果の路線描画を削除
  const deleteLineObjects = useCallback(() => {
    for (let lineObject of lineObjects) {
      lineObject.setMap(null);
    }
  }, [lineObjects]);

  // マーカーの内容が変更された時走る
  useEffect(() => {
    // 中心点を変更
    setCenter({
      lat: marker[0]?.latitude ?? 35.0,
      lng: marker[0]?.longitude ?? 135.0,
    });
    // 拡大率の計算と変更
    changeZoom(marker);
    // 前回検索結果の路線描画を削除
    deleteLineObjects();
    // 描画すべき路線の検索と保存
    const saveLineObjects = seekRouteMarker(marker, routeInfo);
    // 路線描画
    setLineObjects(saveLineObjects);
    const forClusteringMarker = marker.map((m) => ({
      type: "Feature",
      properties: { cluster: false, id: m.stationId, time: m.time },
      geometry: {
        type: "Point",
        coordinates: [m.longitude, m.latitude],
      },
    }));
    setPoints(forClusteringMarker);
    // deleteLineObjectsをdepsに入れろとWarningするが、入れると無限ループになるのでWarningを無視する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker, routeInfo, seekRouteMarker]);

  return (
    <MapCanvasElement>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBxh_XYVtfnz17DWdNgrs7TlmnvqiQSltM" }}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setmap(map);
          setmaps(maps);
          map.setOptions({ mapTypeControl: true });
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster } = cluster.properties;
          if (isCluster) {
            return (
              <ClusterMarker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                cluster={cluster}
                supercluster={supercluster}
                onZoomChange={(zoom: number) => {
                  setZoom(zoom);
                  // 拡大率が変更されたときは中心地も合わせて変更する
                  setCenter({ lat: latitude, lng: longitude });
                }}
              />
            );
          }
          return (
            <MapMarker
              key={cluster.properties.id}
              lat={latitude}
              lng={longitude}
              minutes={cluster.properties.time}
            />
          );
        })}
      </GoogleMapReact>
      <Snackbar
        open={IsAlertOpen}
        autoHideDuration={4000}
        onClose={() => {
          setIsAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsAlertOpen(false);
          }}
          severity="warning"
        >
          路線の情報を取得できませんでした。
        </Alert>
      </Snackbar>
    </MapCanvasElement>
  );
};
