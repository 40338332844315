import "./App.css";
import { MapCanvas } from "./views/MapCanvas";
import { MainMenu } from "./views/MainMenu";
import { useState } from "react";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";

const MenuToggleButton = styled(IconButton)`
  top: 70px;
  width: 48px;
  height: 48px;
  position: absolute;
  z-index: 99;
  background-color: white;
`;

const OpenMenuButton = styled(MenuToggleButton)`
  left: 310px;
`;

const CloseMenuButton = styled(MenuToggleButton)`
  left: 10px;
`;

export type MarkerType = {
  stationId: number;
  latitude: number;
  longitude: number;
  name: string;
  time: number;
  transfer: number;
};

function App() {
  const [marker, setMarker] = useState<MarkerType[]>([]);
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="App">
      {isOpen && (
        <OpenMenuButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <ArrowLeft />
        </OpenMenuButton>
      )}
      {!isOpen && (
        <CloseMenuButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <ArrowRight />
        </CloseMenuButton>
      )}
      <MapCanvas marker={marker} />
      {isOpen && (
        <MainMenu
          onMarkerChange={(marker: MarkerType[]) => {
            setMarker(marker);
          }}
        />
      )}
    </div>
  );
}

export default App;
