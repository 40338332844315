import { FC, useMemo, useState } from "react";
import { Autocomplete, styled, TextField } from "@mui/material";

const InputText = styled(TextField)`
  margin-top: 10px;
  margin-left: 25px;
  width: 220px;
  float: left;
`;

type Props = {
  selectedSuggest: (station: string) => void;
  popularStation: string;
  isPressEnter: (press: boolean) => void;
};

export const SuggestStation: FC<Props> = ({
  selectedSuggest,
  popularStation,
  isPressEnter,
}) => {
  const [suggest, setSuggest] = useState<string[]>([]);
  const stationSuggestion = async (keyword: string) => {
    if (keyword.length === 0) {
      setSuggest([]);
      return;
    }
    const path = process.env.REACT_APP_API ?? "";
    const response = await fetch(
      `${path}station_suggestion?keyword=${keyword}`
    );
    if (!response.ok) {
      // 駅名候補はコンテンツとして表示されなくても最悪問題はないので、エラーは出さない
      return;
    }
    const j = await response.json();
    setSuggest(j.data);
  };
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      isPressEnter(true);
    }
  };

  const defaultStation = useMemo(() => {
    return popularStation;
    // popularStationを入れると無限ループになるのでWarningを無視する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      freeSolo
      options={suggest}
      defaultValue={defaultStation}
      inputValue={popularStation}
      filterOptions={(x) => x}
      noOptionsText={"駅名候補が表示されます"}
      onInputChange={(event, newInputValue) => {
        stationSuggestion(newInputValue);
        selectedSuggest(newInputValue);
      }}
      renderInput={(params) => (
        <InputText
          {...params}
          label="出発地(駅名)"
          variant="standard"
          onKeyDown={(e) => handleKeyDown(e)}
        />
      )}
    />
  );
};
