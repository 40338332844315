import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Slider,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FC, useEffect, useState } from "react";
import { MarkerType } from "../App";
import { InquiryModal } from "./InquiryModal";
import { SuggestStation } from "./SuggestStation";
import { MailOutline } from "@mui/icons-material";
import { PopularSearchWord } from "./PopularSearchWord";
import { SearchHistory } from "./SearchHistory";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";

const MainPaper = styled(Paper)`
  position: absolute;
  top: 60px;
  left: 0;
  width: 300px;
  height: calc(100% - 100px);
`;

const WrapperBox = styled(Box)`
  width: 100%;
  padding-bottom: 25px;
  border-bottom: 2px solid #eee;
`;

const SearchButton = styled(IconButton)`
  margin-top: 20px;
`;

const GridContainer = styled(Grid)`
  margin-left: 25px;
  margin-top: 20px;
`;

const TransferText = styled(Typography)`
  font-size: 90%;
  background-color: #eeeeee;
  line-height: 1.8em;
`;

const TransferSelect = styled(Select)`
  width: 100px;
  font-size: 90%;
  height: 1.7em;
`;

const MinutesSlider = styled(Slider)`
  margin-top: 10px;
  width: 250px;
  position: relative;
  top: 0;
  left: 0;
`;

const InquiryButton = styled(Button)`
  position: absolute;
  margin-bottom: 20px;
  bottom: 0;
  left: 85px;
`;

type StationSearchType = {
  station: string;
  time: number;
  transfer: number;
};

type Props = {
  onMarkerChange: (marker: MarkerType[]) => void;
};

export const MainMenu: FC<Props> = ({ onMarkerChange }) => {
  const [minutes, setMinutes] = useQueryParam("time", NumberParam);
  const [keyword, setKeyword] = useQueryParam("station", StringParam);
  const [transfer, setTransfer] = useQueryParam("transfer", NumberParam);
  const [history, setHistory] = useState<StationSearchType | null>(null);
  const [isInquiryOpen, setIsInquiryOpen] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  // 駅検索
  const searchStation = async (
    keyword: string,
    minutes: number,
    transfer: number
  ) => {
    const path = process.env.REACT_APP_API ?? "";
    const response = await fetch(
      `${path}search_stations?station=${keyword}&time_limit=${minutes}&max_transfer=${transfer}`
    );
    if (!response.ok) {
      setIsAlertOpen(true);
      return;
    }
    const j = await response.json();
    onMarkerChange(j.data);
    setHistory({
      station: keyword,
      time: minutes,
      transfer: transfer,
    });
  };
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setTransfer(event.target.value as number);
  };
  useEffect(() => {
    if (keyword != null && minutes != null && transfer != null) {
      searchStation(keyword, minutes, transfer);
    }
    // depsを入れると無限ループになるのでWarningを無視する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainPaper>
      <WrapperBox>
        <SuggestStation
          selectedSuggest={(station: string) => {
            setKeyword(station);
          }}
          popularStation={keyword ?? ""}
          isPressEnter={(press: boolean) => {
            if (press === true) {
              searchStation(keyword ?? "", minutes ?? 60, transfer ?? -1);
            }
          }}
        />
        <SearchButton
          onClick={() =>
            searchStation(keyword ?? "", minutes ?? 60, transfer ?? -1)
          }
        >
          <SearchIcon />
        </SearchButton>
        <MinutesSlider
          step={5}
          min={5}
          max={300}
          value={minutes ?? 60}
          onChange={(e: any) => setMinutes(parseInt(e.target.value))}
        />
        <Typography> {minutes ?? 60} 分以内 </Typography>
        <GridContainer container>
          <Grid item xs={3}>
            <TransferText>乗換回数</TransferText>
          </Grid>
          <Grid item xs={5}>
            <TransferSelect
              value={transfer}
              label="乗り換え回数"
              variant="standard"
              defaultValue={-1}
              onChange={handleChange}
            >
              <MenuItem value={-1}>制限なし</MenuItem>
              <MenuItem value={0}>0回</MenuItem>
              <MenuItem value={1}>1回以内</MenuItem>
              <MenuItem value={2}>2回以内</MenuItem>
              <MenuItem value={3}>3回以内</MenuItem>
            </TransferSelect>
          </Grid>
          <Grid item xs={4}></Grid>
        </GridContainer>
      </WrapperBox>
      <SearchHistory
        newHistory={history}
        propSelected={(result) => {
          setKeyword(result.station);
          searchStation(result.station, result.time, result.transfer);
          setMinutes(result.time);
          setTransfer(result.transfer);
        }}
      />
      <PopularSearchWord
        propSelected={(result) => {
          setKeyword(result.station);
          searchStation(result.station, result.time, result.transfer);
          setMinutes(result.time);
          setTransfer(result.transfer);
        }}
      />
      <InquiryButton
        variant="outlined"
        startIcon={<MailOutline />}
        onClick={() => {
          setIsInquiryOpen(true);
        }}
      >
        問い合わせ
      </InquiryButton>
      <InquiryModal
        isOpen={isInquiryOpen}
        onModalChange={(open) => {
          setIsInquiryOpen(open);
        }}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={4000}
        onClose={() => {
          setIsAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsAlertOpen(false);
          }}
          severity="warning"
        >
          検索結果がありません。
        </Alert>
      </Snackbar>
    </MainPaper>
  );
};
