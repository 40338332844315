import { FC } from "react";
import styled from "@emotion/styled";
import pin0 from "../img/pin0.png";
import pin1 from "../img/pin1.png";
import pin2 from "../img/pin2.png";
import pin3 from "../img/pin3.png";
import pin4 from "../img/pin4.png";
import pin5 from "../img/pin5.png";

const MapMarkerElement = styled.div`
  color: white;
  font-weight: bold;
  background-repeat: no-repeat;
  padding: 8px 0px;
  transform: translate(-50%, -50%);
  width :32px;
  height: 48px;
}`;

type Props = {
  lat: number;
  lng: number;
  minutes: number;
};

const resolvePin = (pinNo: number) => {
  switch (pinNo) {
    case 0:
      return pin0;
    case 1:
      return pin1;
    case 2:
      return pin2;
    case 3:
      return pin3;
    case 4:
      return pin4;
    case 5:
      return pin5;
    // 300分の時
    case 6:
      return pin5;
  }
};

export const MapMarker: FC<Props> = ({ minutes }) => {
  const pinNo = minutes === 0 ? 0 : Math.floor(minutes / 60) + 1;
  return (
    <MapMarkerElement style={{ backgroundImage: `url(${resolvePin(pinNo)})` }}>
      {minutes}分
    </MapMarkerElement>
  );
};
