import styled from "@emotion/styled";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Star } from "@mui/icons-material";

const Wrapper = styled(Box)`
  padding-top: 20px;
  min-height: 400px;
  width: 100%;
`;

type StationSearchType = {
  station: string;
  time: number;
  transfer: number;
};

type Props = {
  propSelected: (result: StationSearchType) => void;
};

export const PopularSearchWord: FC<Props> = ({ propSelected }) => {
  const [results, setResults] = useState<StationSearchType[]>([]);
  const searchRecommend = async () => {
    const path = process.env.REACT_APP_API ?? "";
    const response = await fetch(`${path}recommend`);
    if (!response.ok) {
      // よく検索される場所はコンテンツとして表示されなくても最悪問題はないので、エラーは出さない
      return;
    }
    const j = await response.json();
    setResults(j.data);
  };

  useEffect(() => {
    searchRecommend();
  }, []);

  return (
    <Wrapper>
      <Typography variant="subtitle2">よく検索される場所</Typography>
      <List>
        {results.map((result: StationSearchType, i: number) => (
          <ListItem button key={i} onClick={() => propSelected(result)}>
            <ListItemIcon>
              <Star />
            </ListItemIcon>
            <ListItemText>
              {result.station} - {result.time}分
              {result.transfer === -1 ? "" : ` - 乗換${result.transfer}回`}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};
