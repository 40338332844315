import { FC } from "react";
import styled from "@emotion/styled";
import mark1 from "../img/marker1.png";
import mark2 from "../img/marker2.png";
import mark3 from "../img/marker3.png";
import mark4 from "../img/marker4.png";
import mark5 from "../img/marker5.png";

const ClusterMarkerElement = styled.div`
  color: white;
  font-weight: bold;
  background-repeat: no-repeat;
  padding: 15px 0px;
  transform: translate(-50%, -50%);
  width :48px;
  height: 48px;
}`;

type Props = {
  lat: number;
  lng: number;
  cluster: any;
  supercluster: any;
  onZoomChange: (zoom: number) => void;
};

const resolvePin = (pinNo: number) => {
  switch (pinNo) {
    case 1:
      return mark1;
    case 2:
      return mark2;
    case 3:
      return mark3;
    case 4:
      return mark4;
    case 5:
      return mark5;
    // 300分の時
    case 6:
      return mark5;
  }
};

export const ClusterMarker: FC<Props> = ({
  cluster,
  supercluster,
  onZoomChange,
}) => {
  // 子要素の中で一番大きなtimeを求める
  const timearr = supercluster
    .getLeaves(cluster.id, 1000)
    .map((marker: any) => {
      return marker.properties.time;
    });
  const minutes = Math.max(...timearr);
  const pinNo = minutes === 0 ? 0 : Math.floor(minutes / 60) + 1;

  return (
    <ClusterMarkerElement
      style={{ backgroundImage: `url(${resolvePin(pinNo)})` }}
      onClick={() => {
        // クリックされたときの拡大率を計算
        const expansionZoom = Math.min(
          supercluster.getClusterExpansionZoom(cluster.id),
          20
        );
        onZoomChange(expansionZoom);
      }}
    >
      ~{minutes}分
    </ClusterMarkerElement>
  );
};
